<template>
  <v-form>
    <v-row dense>
      <v-col cols="3">
        <v-text-field
          outlined
          dense
          label="First Name"
          v-model="record.firstName"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          outlined
          dense
          label="Last Name"
          v-model="record.lastName"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          outlined
          dense
          label="Middle Name"
          v-model="record.middleName"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          outlined
          dense
          v-model="record.sex"
          :items="sex"
          item-text="text"
          item-value="value"
          label="Gender"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="3">
        <v-text-field
          outlined
          dense
          label="Employee Code"
          v-model="record.empID"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          outlined
          dense
          label="Mobile"
          v-model="record.mobile"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          outlined
          dense
          label="Email"
          v-model="record.email"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          outlined
          dense
          label="National ID"
          v-model="record.govID"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="3">
        <v-autocomplete
          outlined
          dense
          :items="CostCenters"
          item-text="PrcName"
          item-value="PrcCode"
          label="Cost Center"
          v-model="record.CostCenter"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          outlined
          dense
          v-model="record.userId"
          :items="users"
          item-text="name"
          item-value="id"
          label="User Code"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-text-field
          outlined
          dense
          label="Job Title"
          v-model="record.jobTitle"
        ></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-checkbox
          outlined
          dense
          v-model="record.Active"
          label="Active Employee"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="3">
        <v-autocomplete
          outlined
          dense
          v-model="record.manager"
          :items="employees"
          item-value="empID"
          item-text="full_name"
          label="Manager"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          outlined
          dense
          v-model="record.salesPrson"
          :items="SalesEmployees"
          item-text="SlpName"
          item-value="SlpCode"
          label="Sales Person"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          outlined
          dense
          v-model="record.dept"
          :items="departments"
          item-text="Name"
          item-value="id"
          label="Department"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          outlined
          dense
          v-model="record.branch"
          :items="branches"
          item-text="BPLName"
          item-value="id"
          label="Branch"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    record: {},
    PayModes: [],
    selectedLine: null,
    paymentLines: null,
    selectedAcct: null,
    paymentAccounts: null,
    Warehouses: [],
    Payment: {},
    WhsAssgn: {},
    search: null,
    searchWhs: null,
    loader: false,
    branches: [],
    departments: [],
    users: [],
    Dialog: false,
    WhsDialog: false,
    SalesEmployees: [],
    CostCenters: [],
    employees: [],
    date: new Date().toISOString().substr(0, 10),
    sex: [
      { text: "Male", value: "M" },
      { text: "Female", value: "F" },
      { text: "Not Specified", value: "E" },
    ],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    sendData() {
      this.loader = true;
      this.$emit("data", this.record);
    },
    getBranches() {
      const self = this;
      this.$store
        .dispatch("get", `/branches?addingUser=1`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.branches = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getDepartments() {
      const self = this;
      this.$store
        .dispatch("get", `/departments`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.departments = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getEmployees() {
      const self = this;
      this.$store
        .dispatch("get", `/employee-master-data`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.employees = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    //cost-centers
    getCostCenters() {
      const self = this;
      this.$store
        .dispatch("get", `/cost-centers`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.CostCenters = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getWarehouses() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/warehouse`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.Warehouses = res.ResponseData;
            self.loading = false;
          }
          if (res.ResultCode == 1043) {
            this.$refs.snackbar.show(res.ResultDesc, "red");
            self.loading = false;
          }
        })
        .catch((err) => {
          self.loading = false;
          this.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },
    getUsers() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/users`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.users = res.ResponseData;
            self.loading = false;
          }
          if (res.ResultCode == 1043) {
            this.$refs.snackbar.show(res.ResultDesc, "red");
            self.loading = false;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
          self.loading = false;
        });
    },
    getSalesEmployees() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/employee`)
        .then((res) => {
          self.SalesEmployees = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getBranches();
    this.getDepartments();
    this.getEmployees();
    this.getUsers();
    this.getSalesEmployees();
    this.getWarehouses();
    this.getCostCenters();
  },
};
</script>
